.traffic-lights-link {
  border-radius: 50% !important;
  bottom: -15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  padding: 10px;
  text-decoration: none !important;
  transition: all 0.2s;
  z-index: 9;

  span {
    color: #fff;
    font-size: 0;
    text-transform: uppercase;
    transition: all 0.2s;
  }

  &:hover {
    border-radius: 20px !important;

    span {
      font-size: 14px;
      padding: 0 10px;
    }
  }
}

.traffic-lights-event {
  color: #0000ba;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px !important;
  height: 45px;

  .badge-container {
    align-items: center;
    background: #173042;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    height: 24px;
    width: 24px;
  }

  .event-details {
    display: flex;

    .text-container {
      display: flex;
      padding-left: 10px;

      .event-title {
        font-size: 14px;
        margin: 0;

        span {
          text-transform: uppercase;
        }
      }

      .event-points {
        font-size: 11px;
        font-weight: 700;
        margin: 0 0 0 5px;
      }
    }
  }

  &.latest {
    height: 100px;

    .badge-container {
      height: 60px;
      width: 60px;
      min-width: 60px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;

      .event-title {
        text-transform: uppercase;
      }

      .event-title,
      .event-points {
        padding-left: 20px;
        margin: 0;
      }
    }
  }

  .event-date {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
  }
}

.load-more-events {
  color: #0000ba !important;
  float: right;
  font-size: 10px !important;
  font-weight: 700 !important;
  margin-bottom: 50px !important;
  margin-right: 20px !important;
  text-align: right !important;
  text-decoration: none !important;
}

.clearfix {
  clear: both;
}

.badge-icon-container {
  align-items: center;
  background: #173042;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
}

.traffic-lights-description-header {
  margin-bottom: 0 !important;
}

.traffic-lights-h3 {
  margin-bottom: 10px !important;
}

.teacher-card-avatar {
  display: flex;
  justify-content: center;
  position: relative;

  .teacher-card-avatar-badge {
    bottom: -10px;
    display: block;
    position: absolute;
  }
}
