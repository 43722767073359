html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiContainer-maxWidthXl {
  max-width: 1300px !important;
}

main {
  flex: 1 0 auto;
  margin-top: 55px;
}

footer {
  flex-shrink: 0;
  background-color: #dce6e0;
  border-top: 4px #253d4e solid;
  padding: 37px 0;
}

/** SM */
@media (min-width: 600px) {
  main {
    margin-top: 64px;
  }
}
/** MD */
@media (min-width: 900px) {
  main {
    margin-top: 68px;
  }
}
